package com.PebblesBrewsAndBites.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Page
@Composable
fun HomePage() {
    Style(AppStylesheet)

    Box(
        Modifier.fillMaxSize()
            .padding(16.px)
            .backgroundColor(rgb(210, 180, 140)), // Light brown background color
        contentAlignment = Alignment.Center
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxWidth().padding(32.px)
        ) {
//            Img(
//                src = "assets/images/Pebbles_WIth_A_background.png", // Ensure the path is correct
//                attrs = {
//                    style {
//                        width(50.percent)
//                        marginBottom(16.px)
//                    }
//                }
//            )
            Div(
                attrs = {
                    style {
                        color(Color.black)
                        fontSize(32.px)
                        fontWeight("bold")
                        textAlign("center")
                        marginBottom(16.px)
                    }
                }
            ) {
                Text("Pebbles Brews and Bites")
            }
            Div(
                attrs = {
                    style {
                        color(Color.black)
                        fontSize(24.px)
                        textAlign("center")
                        marginBottom(32.px)
                    }
                }
            ) {
                Text("Coming Soon")
            }
        }
    }
}

object AppStylesheet : StyleSheet() {
    init {
        "body" style {
            margin(0.px)
            padding(0.px)
            fontFamily("Arial, sans-serif")
            height(100.vh)
        }
        "html" style {
            height(100.vh)
        }
    }
}
